<template>
    <div id="MobileTerminal">
        <div class="tab-container">
            <div class="tab-main">
                <div class="tab-font active">
                    <img src="../../assets/img/mobileTerminal/kbmb.png" alt="">
                    <span>空白模板</span>
                </div>
            </div>
        </div>
        <div class="mobileTerminal-main">
            <div class="title">空白合同模板</div>
            <ul class="left">
                <li v-for="item in classification" :class="templateClassificationId == item.templateClassificationId ? 'active' : ''" :key="item.templateClassificationId" @click="changeClassification(item.templateClassificationId)">
                    <span>{{item.templateClassificationName}}</span>
                </li>
            </ul>
            <ul class="right">
                <li v-for="item in template" :key="item.templateId">
                    <a :download="item.fileName" :href="item.templateUrl">
                        {{item.fileName}}
                    </a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import * as Server from '@/api/server.js'
    export default {
        name: "mobileBlankTemplate",
        data() {
            return {
                classification:[],
                template:[],
                templateClassificationId:''
            }
        },
        mounted() {
            this.getClassificationList()
        },
        methods: {
            async getClassificationList(){
                //请求数据
                let data = {
                    method: 'post',
                    url: 'themis-terminal-api/b/q/blankTemplate/classificationPageList/',
                    config: {
                        data: {
                            pageSize:500
                        },
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                this.classification = dataSource.data.returnData.data
                this.templateClassificationId = this.classification[0].templateClassificationId
                this.getTemplateList(this.classification[0].templateClassificationId)
            },
            async getTemplateList(id){
                //请求数据
                let data = {
                    method: 'post',
                    url: 'themis-terminal-api/b/q/blankTemplate/pageList',
                    config: {
                        data: {
                            blankTemplate:{
                                templateClassificationId:id
                            },
                            dataTablesPage:{
                                pageSize: 500
                            }
                        },
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                this.template = dataSource.data.returnData.data
            },
            changeClassification(id){
                this.templateClassificationId = id
                this.getTemplateList(id)
            }
        }
    }
</script>

<style scoped lang="less">
    .tab-container {
        padding: .3rem .3rem 0 .3rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100vw;
        height: 1.72rem;
        background-color: #0043A7;

        .tab-main {
            width: 100%;
            display: flex;
            height: 100%;
            overflow: auto;

            .tab-font {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    width: .5rem;
                    height: .5rem;
                    margin-top: .06rem;
                }

                span {
                    font-size: .26rem;
                    font-weight: bold;
                    color: rgba(255, 255, 255, 0.9);
                    margin-top: .1rem;
                }
            }
        }

        .active {
            background-color: #FFA525;
            border-radius: 10px 10px 0px 0px;
        }
    }

    #MobileTerminal {
        width: 100vw;
        min-height: 100vh;
        background-color: #0043A7;
        padding: 0 .3rem;
        overflow-x: hidden;
        display: flex;

        .mobileTerminal-main {
            margin-top: 1.72rem;
            flex: 1;
            background-color: #fff;
            position: relative;
            height: calc(100vh - 2rem);
            overflow: hidden;

            .title {
                font-size: 18px;
                font-weight: bold;
                color: #358CF3;
                height: 1.09rem;
                line-height: 1.09rem;
                margin: 0;
                background-color: rgba(234, 243, 254);
                text-align: left;
                padding-left: .3rem;
            }

            .left {
                width: 30%;
                background: #F9F9F9;
                height: calc(100vh - 3.1rem);
                padding: 0;
                overflow: auto;
                margin: 0;
                position: absolute;
                top: 1.09rem;
                left: 0;

                li {
                    padding: 0 4px;
                    border-bottom: 1px solid #0043a6;
                    color: #333333;
                    font-size: .28rem;
                    word-break: break-word;
                    height: .85rem;
                    display: flex;
                    align-items: center;
                    span{
                        overflow : hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    &.active {
                        color: #3E8BFF;
                    }
                }
            }

            .right {
                width: 70%;
                height: calc(100vh - 3.1rem);
                padding-left: 6px;
                position: absolute;
                top: 1.09rem;
                right: 0;
                overflow: auto;

                li {
                    border-bottom: 1px solid #DDDDDD;
                    padding: .2rem;

                    a {
                        color: #3E8BFF;
                    }
                }
            }
        }
    }
</style>